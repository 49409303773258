import Caption from 'primitives/Typography/BodyCopy/Caption';
import H4 from 'primitives/Typography/Headings/H4';
import H5 from 'primitives/Typography/Headings/H5';
import NavLink from 'components/NavLink';
import RailItems from 'views/Podcast/primitives/RailItems';
import RailSection from 'views/Podcast/primitives/RailSection';
import Row from './primitives/Row';
import RowText from './primitives/RowText';
import RowThumb from './primitives/RowThumb';
import ShouldShow from 'components/ShouldShow';
import Truncate from 'components/Truncate';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Podcast } from 'state/Podcast/types';
import { TILE_RES } from 'components/MediaServerImage';

type Props = {
  itemSelectedLocation?: string;
  length?: number;
  popularCategoryPodcasts: Array<Podcast>;
};

function PopularPodcasts({
  itemSelectedLocation,
  length = 3,
  popularCategoryPodcasts = [],
}: Props) {
  const translate = useTranslate();

  return (
    <ShouldShow shouldShow={popularCategoryPodcasts.length > 0}>
      <RailSection>
        <H4>{translate('Popular Podcasts')}</H4>

        <RailItems>
          {popularCategoryPodcasts
            .slice(0, length)
            .map(({ description, seedId, seedType, title, url }) => {
              const itemSelected =
                itemSelectedLocation ?
                  {
                    id: seedId,
                    location: itemSelectedLocation,
                    name: title,
                    type: seedType,
                  }
                : null;

              return (
                <Row key={`${seedId}-${seedType}`}>
                  <NavLink
                    css={{ display: 'block', position: 'relative' }}
                    itemSelected={itemSelected}
                    to={url}
                  >
                    <RowThumb
                      alt={title}
                      aspectRatio={1}
                      height={TILE_RES}
                      id={seedId}
                      type={seedType}
                      width={TILE_RES}
                    />
                  </NavLink>

                  <RowText>
                    <NavLink
                      css={{ display: 'block', position: 'relative' }}
                      itemSelected={itemSelected}
                      to={url}
                    >
                      <H5>
                        <Truncate lines={1}>{title}</Truncate>
                      </H5>
                    </NavLink>

                    <Caption>
                      <Truncate lines={1}>{description}</Truncate>
                    </Caption>
                  </RowText>
                </Row>
              );
            })}
        </RailItems>
      </RailSection>
    </ShouldShow>
  );
}

export default PopularPodcasts;
