import Caption from 'primitives/Typography/BodyCopy/Caption';
import styled from '@emotion/styled';

const RowText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1.2rem',
  width: '100%',

  [Caption.toString()]: {
    color: theme.colors.gray['400'],
    marginTop: '0.4rem',
  },
}));

export default RowText;
