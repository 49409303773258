import styled from '@emotion/styled';

const Row = styled('div')({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '1.2rem',
  width: '100%',

  '&:last-child': {
    marginBottom: 0,
  },
});

export default Row;
