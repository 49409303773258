import CatalogImage from 'components/MediaServerImage/CatalogImage';
import styled from '@emotion/styled';

const RowThumb = styled(CatalogImage)({
  borderRadius: '0.3rem',
  boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.15)', // elevation 1
  cursor: 'pointer',
  height: '6rem',
  minWidth: '6rem',
  overflow: 'hidden',
  width: '6rem',

  '&:hover::after': {
    opacity: 1,
  },

  '&::after': {
    backgroundColor: 'rgba(24, 24, 24, 0.75)', // mask hover styles
    content: 'open-quote',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: 'opacity 0.1s',
    transitionTimingFunction: 'cubic-bezier(0.7, 0, 0.3, 1)',
    width: '100%',
  },
});

export default RowThumb;
